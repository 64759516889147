import {useNavigate  } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import PromptService from "../services/PromptService";
import "../styles/bootstrap.min.css";
import SideBar from "./Sidebar";
import LoginCheck from "./LoginCheck";
import DashboardSection from "./DashboardSection";
import LanguageSection from "./LanguageSection";
import TopBar from "./Topbar";
import CloseCircle  from "../images/cross-circle.png";
import UserAvatar  from "../images/user-avatar.png";
import OurValues  from "../images/our-values.png";
import ToneOfVoice  from "../images/tone-voice.png";
import Language  from "../images/language.png";
import JobResult  from "../images/job-result.png";
import Feedback  from "../images/feedback.png";
import Notification  from "../images/notifications_none.png";
import Theme  from "../images/moon-solid.png";
import Information  from "../images/info_outline.png";
import { useTranslation } from 'react-i18next';

function Dashboard() {
  document.body.style.backgroundColor = '#FFFFFF';
  const { t } = useTranslation();
  const [dashboardSection, setDashboardSection] = useState("vareverdier");
  const [activeSection, setActiveSection] = useState("vareverdier");
  const [savePromptMsg, setSavePromptMsg] = useState("");
   // State for each table
  const [vareverdierRows, setVareverdierRows] = useState([
    { id: 1, heading: '', description: '' }
  ]);
  const [toneofvoiceRows, setToneofvoiceRows] = useState([
    { id: 1, heading: '', description: '' }
  ]);
  const savePrompt = (section) => {
    if(section=="vareverdier"){
       PromptService.savePrompt(1, section, vareverdierRows).then(
      (response) => {
        setSavePromptMsg(response.message);
        //console.log(response.message);
      },
      (error) => {
        console.log(error);
      });
    } else if(section=="toneofvoice"){
      PromptService.savePrompt(1, section, toneofvoiceRows).then(
      (response) => {
        setSavePromptMsg(response.message);
      },
      (error) => {
        console.log(error);
      });
    }
    

  }
  useEffect(() => {
    PromptService.getPromptByType(1, "vareverdier").then(
    (response) => {
      setVareverdierRows(JSON.parse(response.prompt.prompt));
    },
    (error) => {
      console.log(error);
    });
    PromptService.getPromptByType(1, "toneofvoice").then(
    (response) => {
      setToneofvoiceRows(JSON.parse(response.prompt.prompt));
    },
    (error) => {
      console.log(error);
    });
  },[])
  console.log("toneofvoiceRows", toneofvoiceRows);
	return (
        <div>
            <div className="container-fluid">
              <div className="row">
                
                <LoginCheck />

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-none d-block align-items-center pt-3 pb-2 mb-3">
                      <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                          </button>
                    </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
                    <div className="col-md-3 col-lg-8 p-4 ">
                      <h1 className="h1">{t('firmainformasjon')}</h1>
                      <div className="breadcrumb">
                        {t('firmainformasjon')} &raquo;
                      </div>
                    </div>

                    <TopBar />
                    
                  </div>

                  
                  <div className="container-fluid" >
                    <div className="row"> 
                         <div className={dashboardSection=='vareverdier' ? 'fm-top-box-active fm-top-box col-xs-12 col-sm-12 col-md-6 col-lg-2 col-lg-offset-1 m-2 p-3' : 'fm-top-box col-xs-12 col-sm-12 col-md-6 col-lg-2 col-lg-offset-1 m-2 p-3'} align="center" onClick={() => { setDashboardSection('vareverdier'); setSavePromptMsg(""); }  }>
                            <img src={OurValues} />
                            <h1>{t('ourvalue')}</h1>
                         </div>
                         
                         <div className={dashboardSection=='toneofvoice' ? 'fm-top-box-active fm-top-box col-xs-12 col-sm-12 col-md-6 col-lg-2 col-lg-offset-1 m-2 p-3' : 'fm-top-box col-xs-12 col-sm-12 col-md-6 col-lg-2 col-lg-offset-1 m-2 p-3'} align="center" onClick={() => {setDashboardSection('toneofvoice'); setSavePromptMsg("");}}>
                          <img src={ToneOfVoice} />
                            <h1>{t('toneofvoice')}</h1>
                         </div>
                         {/*
                         <div className={dashboardSection=='language' ? 'fm-top-box-active fm-top-box col-xs-12 col-sm-12 col-md-6 col-lg-2 col-lg-offset-1 m-2 p-3' : 'fm-top-box col-xs-12 col-sm-12 col-md-6 col-lg-2 col-lg-offset-1 m-2 p-3'} align="center" onClick={() => {setDashboardSection('language'); setSavePromptMsg("");}}>
                          <img src={Language} />
                            <h1>{t('language')}</h1>
                         </div>
                         
                         <div className="fm-top-box col-xs-12 col-sm-12 col-md-6 col-lg-2 col-lg-offset-1 m-2 p-3" align="center">
                          <img src={JobResult} />
                            <h1>{t('jobresult')} </h1>
                         </div>
                         
                         <div className="fm-top-box col-xs-12 col-sm-12 col-md-6 col-lg-2 col-lg-offset-1 m-2 p-3" align="center">
                          <img src={Feedback} />
                            <h1>{t('feedback')} </h1>
                         </div>
                       */}
                     </div>
                  </div>
                  
                  {dashboardSection=="vareverdier" ?
                   
                  <>
                  <div className="container-fluid mt-4 vareverdier">
                    <div className="row">
                         <div className="col-12 fm-content-box p-4"> 
                         <DashboardSection rows={vareverdierRows} setRows={setVareverdierRows} tableId="vareverdier" title={t('ourvalue')}/>
                         <button className="btn fm-btn"  onClick={() => savePrompt('vareverdier')} type="button">{t('save')}</button> {savePromptMsg ? savePromptMsg : ''}
                         </div>
                     </div>
                  </div>
                  </>
                  :
                  dashboardSection=="toneofvoice" ?
                  
                  <>
                  <div className="container-fluid mt-4 toneofvoice">
                    <div className="row">
                        <div className="col-12 fm-content-box p-4">  
                        <DashboardSection  rows={toneofvoiceRows} setRows={setToneofvoiceRows} tableId="toneofvoice"  title={t('toneofvoice')} /> 
                        <button className="btn fm-btn"  onClick={() => savePrompt('toneofvoice')} type="button">{t('save')}</button> {savePromptMsg ? savePromptMsg : ''}
                        </div>
                     </div>
                  </div>
                  </> 
                  :
                  <>
                  <div className="container-fluid mt-4 toneofvoice">
                    <div className="row">
                        <LanguageSection /> 
                     </div>
                  </div>
                  </> 
                  }
                  
                  
                </main>
              </div>
            </div>
        	
        </div>
        )
}
export default Dashboard;