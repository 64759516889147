import {useParams, NavLink  } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AssistantService from "../services/AssistantService";
import UserService from "../services/UserService";
import PIMServices from "../services/PIMServices";
import ProductService from "../services/ProductService";
import DropdownComponent from "./DropdownComponent";
import "../styles/bootstrap.min.css";
import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import { useTranslation } from 'react-i18next';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css'; // Import the CSS for styling
import moment from 'moment';
import { Dropdown } from 'semantic-ui-react'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//import 'semantic-ui-css/semantic.css';
function WorkSchedule() {
  const { appslug } = useParams();

  document.body.style.backgroundColor = '#FFFFFF';
  const [scheduleMsg, setScheduleMsg] = useState("");
  const [organizationID, setOrganizationID] = useState(parseInt(window.sessionStorage.getItem('organization_id'), 10));
  const [scheduleISBN, setScheduleISBN] = useState("");
  const [assistantID, setAssistantID] = useState("");
  const [dailyWeeklyMonthly, setDailyWeeklyMonthly] = useState();
  const [attributesList, setAttributesList] = useState();
  const [monthDay, setMonthDay] = useState();
  const [showSEOIntervals, setShowSEOIntervals] = useState(false);
  const [showMassSEOCriteria, setShowMassSEOCriteria] = useState(true);
  const [showMassSEOSchedule, setShowMassSEOSchedule] = useState(true);
  const [selectedTime, setSelectedTime] = useState();
  const [saveFrequencyMsg, setSaveFrequencyMsg] = useState();
  const [saveMassFrequencyMsg, setSaveMassFrequencyMsg] = useState();
  const [lastCronTime, setLastCronTime] = useState();
  const [selectedAttribute, setSelectedAttribute] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [attributeList, setAttributeList] = useState([]);
  const [attributeSEOStatsTotal, setAttributeSEOStatsTotal] = useState();
  const [attributeSEOStats, setAttributeSEOStats] = useState();
  const [totalProducts, setTotalProducts] = useState();
  const [massAttributeIDs, setMassAttributeIDs] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [savedProductAttributeGroup, setSavedProductAttributeGroup] = useState();
  const [selectedOption, setSelectedOption] = useState([]);
  //console.log("selectedOption", selectedOption);
  /*const processAttributes = (attributes) => {
    // Create an empty object to group by attribute_group
    const groupedAttributes = {};

    attributes.forEach((attr) => {
      const groupID = attr.attribute_group;
      const value = attr.attribute_value;

      // If the group doesn't exist in the object, initialize it
      if (!groupedAttributes[groupID]) {
        groupedAttributes[groupID] = {
          attribGroupID: groupID,
          values: [[]], // Initialize values as an array containing a sub-array
        };
      }

      // Add a new condition to check if the last sub-array has 2 items
      if (groupedAttributes[groupID].values[groupedAttributes[groupID].values.length - 1].length >= 2) {
        // If the last sub-array already has 2 items, push a new empty array
        groupedAttributes[groupID].values.push([]);
      }

      // Push the attribute_value into the last sub-array
      groupedAttributes[groupID].values[groupedAttributes[groupID].values.length - 1].push(value);
    });

    // Convert the grouped object into an array
    return Object.values(groupedAttributes);
  };*/

  useEffect(() => {
    setSaveFrequencyMsg("")
    PIMServices.PIMAttributes(organizationID).then(
      (response) => {
        setAttributesList(response.data)
      }
    ).catch(
      (error) => {
        console.log("error fetching attributes", error);
      }
    )

    AssistantService.getAssistantIDBySlug(appslug).then(
      (assistresponse) => {
          setAssistantID(assistresponse.assistantID)

          /*AssistantService.GetSEOCriteriaAndSchedule(assistresponse.assistantID).then(
            (response) => {
              //console.log("response.productatttribute", response.productatttribute);
              //const transformedData = processAttributes(response.productatttribute);
             // console.log("transformedData", transformedData);
              // Set the transformed data to massAttributeIDs
             
              
              const dateString = response.massfrequency.execution_time;
              const formattedDate = new Date(dateString.replace(' ', 'T'));
              setStartDate(formattedDate)
              setSelectedDate(dateString);
              const idsArray = response.massfrequency.attribute_ids.split(',').map(Number); // Split and convert to numbers
               setSelectedOption(idsArray);
              //setSelectedOption(idsArray);
              //massAttributeIDs(idsArray);
              //setSelectedOption(response.massfrequency.attribute_ids)
              //setSavedProductAttributeGroup(response.productatttribute);
            }
          ).catch(
            (error) => {
              console.log("Error getting SEO frequency", error);
            }
          )*/

          AssistantService.GetSEOFrequency(assistresponse.assistantID).then(
            (response) => {
              const parsedLastCronDate = new Date(response.seofrequency.last_cron_date);
              const currentDate = new Date();
              const differenceInMillis = currentDate - parsedLastCronDate;
              const differenceInHours = Math.floor(differenceInMillis / (1000 * 60 * 60));
              setShowSEOIntervals(true)
              setDailyWeeklyMonthly(response.seofrequency.frequency)
              setSelectedTime(response.seofrequency.time)
              setMonthDay(response.seofrequency.days)
              setSelectedDays(JSON.parse(response.seofrequency.days))
              const idsArray = response.seofrequency.attribute_ids.split(',').map(Number); // Split and convert to numbers
               setSelectedOption(idsArray);
              setLastCronTime(differenceInHours)
              //console.log("response", JSON.parse(response.pimfrequency.days));
            }
          ).catch(
            (error) => {
              console.log("Error fetching SEO Frequency", error)
            }
          )
      }
    ).catch(
      (error) => {
        console.log("error getting assitant ID", error);
      }
    )

    PIMServices.GetPIMFrequency(organizationID).then(
      (response) => {
        setSelectedAttribute(response.attributes)
        response.attributes.map((values, keys) => {

          AssistantService.GetAttributeGroupValuesById(values.id).then(
            (response) => {
                //console.log("response", response.data.attributes);
                setAttributeList(prevList => {
                // Create a new group for each `values.id`
                const newGroup = {
                  id: values.id,
                  name: values.name,
                  items: Array.isArray(response.data.attributes) 
                    ? response.data.attributes.map(val => ({
                        value: val.id,
                        text: val.attribute_value
                      }))
                    : []
                };
                const updatedList = prevList.filter(group => group.id !== values.id);
                  // Assign the new list at index 0
                   return [...(updatedList || []), newGroup];
                });
              
              
            }
          ).catch(
            (error) => {
              console.log("error getting attributes values", error);
            }
          )
        })
      }
    ).catch(
      (error) => {
        console.log("Error fetching PIM frequency", error)
      }
    )


  }, [appslug])
  //console.log("assistantID", assistantID)
  const [selectedDays, setSelectedDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
   const selectWeeklyDays = (day) => {
    setSelectedDays((prevSelectedDays) => ({
      ...prevSelectedDays,
      ...day,
    }));
  };
  //console.log("selectedTime", selectedTime.format('hh:mm:ss'));
  const days = Array.from({ length: 31 }, (_, index) => index + 1);
  const handleMonthDayChange = (event) => {
    setMonthDay(event.target.value);
  }
  const handleSelectChange = (event) => {
    setSelectedDays({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    })
    setDailyWeeklyMonthly(event.target.value);
  };
  const times = Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, '0'); // Pad single digits with a leading zero
    return `${hour}:00`;
  });
  const handleTimeChange = (event) => {
    /*if (time) {
        // Set the minutes to 00
        const adjustedTime = time ? time.minutes(0) : null;*/
        setSelectedTime(event.target.value+':00');
    //}
    ///setSelectedTime(value);
  };
  const { t } = useTranslation();
  const saveSEOCriteriaAndSchedule = () => {
    /*const params = {
      frequency : dailyWeeklyMonthly,
      days : dailyWeeklyMonthly=='weekly' ? JSON.stringify(selectedDays) : monthDay,
      time : selectedTime,
    }*/
    AssistantService.SetSEOCriteriaAndSchedule(organizationID, assistantID, selectedDate, selectedOption.join(',')).then(
      (response) => {
        console.log(response);
        setSaveMassFrequencyMsg(response.message);
      }
    ).catch(
      (error) => {
        console.log("Error setting SEO Mass frequency", error);
      }
    )
  }

  const saveSEOFrequency = () => {
    const params = {
      frequency : dailyWeeklyMonthly,
      days : dailyWeeklyMonthly=='weekly' ? JSON.stringify(selectedDays) : monthDay,
      time : selectedTime,
    }
    AssistantService.SetSEOFrequency(organizationID, assistantID, dailyWeeklyMonthly, params.days, params.time, selectedOption.join(',')).then(
      (response) => {
        console.log(response);
        setSaveFrequencyMsg(response.message);
      }
    ).catch(
      (error) => {
        console.log("Error setting SEO frequency", error);
      }
    )
    //console.log(dailyWeeklyMonthly, selectedDays, monthDay, selectedTime.format('hh:mm:ss'));
  }
  

  const handleChange = (event) => {
    // Update the state with the new input value
    setScheduleISBN(event.target.value);
  };
   const addSchedule = () => {
    ProductService.addSchedule(organizationID, assistantID, scheduleISBN).then(
      (response) => {
        setScheduleMsg(response.message);
      }
    ).catch(
      (error) => {
        //setScheduleMsg(error.response.data.error);
        console.log("Error setting schedule", error);
      }
    )
  }
  const handleDateChange = (date) => {
    setStartDate(date)
    // Update the state with the selected date
    const formattedDate = date 
      ? `${date.toLocaleDateString('en-CA')} ${date.toLocaleTimeString('en-GB', { hour12: false })}`
      : '';
      setSelectedDate(formattedDate); 
    console.log("Formatted Date:", formattedDate);
  };

    const handleDropDownChange = (event, data, attribGroupID) => {
      console.log("data.value", data.value, attribGroupID);
      setSelectedOption(data.value)

      //const oldValues = document.getElementById('seostats-text-').value
      AssistantService.GetAttributeValueSEOStats(data.value, assistantID).then(
        (response) => {
          document.getElementById('seostats-number').innerHTML = "In this criteria: <b>Total:</b> "+response.data.totalProducts+" <br /><b>SEO Generated:</b> "+response.data.seoGeneratedCount+" . <b>Ready for SEO:</b> "+(response.data.totalProducts-response.data.seoGeneratedCount)
          document.getElementById('seostats-text').value = data.value;
          setMassAttributeIDs(prevArray => {

            // Check if attribGroupID already exists in the array
            const existingEntry = prevArray.find(entry => entry.attribGroupID === attribGroupID);

            if (existingEntry) {
              // If the value is not already present for this attribGroupID, add it
              if (!existingEntry.values.includes(data.value)) {
                return prevArray.map(entry => 
                  entry.attribGroupID === attribGroupID
                   ? { ...entry, values: [ data.value] }
                    : entry
                );
              }
            } else {
              // If attribGroupID doesn't exist, add a new entry for attribGroupID with the new value
              return [...prevArray, { attribGroupID, values: [data.value] }];
            }

            return prevArray; // Return unchanged if the value already exists
          });
        }
      ).catch(
        (error) => {
          console.log("error fetching data from GetAttributeValueSEOCount", error)
          document.getElementById('seostats-number').innerHTML = "";
          document.getElementById('seostats-text').value = "";
          setMassAttributeIDs(prevArray => {
              // Filter out the entry with the specified attribGroupID
              const updatedArray = prevArray.filter(entry => entry.attribGroupID !== attribGroupID);

              return updatedArray;
          });
        }
      )
    };
	return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-none d-block align-items-center pt-3 pb-2 mb-3">
                      <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                          </button>
                    </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
                    <div className="col-md-3 col-lg-8 p-4 ">
                      <h1 className="h1">{t('workschedule')}</h1>
                      <div className="breadcrumb">
                        {t('workschedule')} &raquo;
                      </div>
                    </div>
                    <TopBar />
                  </div>
                  
                  
                  <div className="container-fluid">
                  <hr />
                  </div>
                  <div className="container-fluid mt-4">
                    <div className="row">
                      <div className="">
                        <h4 className="h4">{t('automaticseooptimize')}</h4>
                      </div>
                    </div>
                    <div className="row" >
                      
                      <div className="col-4" >
                        <div className="pt-2">
                         Last SEO update: {lastCronTime} hours ago. Next update happens in {24-lastCronTime} hours.
                        </div>
                      </div>
                      <div className="col-2 text-end" >
                        <NavLink to={"/"+appslug+"/stop"}><button className="btn btn-md fm-btn me-md-2" type="button" >{t('review')}</button></NavLink>
                        <NavLink to={"/"+appslug+"/review"}><button className="btn btn-md fm-btn me-md-2" type="button" >{t('stop')}</button> </NavLink>
                      </div>
                    </div>
                    <div className="row" >
                      <div className="mt-4">
                        <h6 className="h5">{t('selectcriteriaformassupdates')}</h6>
                        {/* }<button className="btn btn-lg fm-btn me-md-2" type="button" onClick={() => setShowMassSEOCriteria(!showMassSEOCriteria)} >{t('selectcriteriaformassupdates')}</button> {*/}
                      </div>
                    </div>
                    {showMassSEOCriteria ? 
                    (
                    <>
                    <div className="row pt-4" >
                      <div className="col-6" >
                        {attributeList ? attributeList.map((value, key) => (
                          <div className="row pt-2">
                            <div className="col-3 attribute-label">
                             {value.name}:
                            </div>
                            <div className="col-9">
                              <Dropdown value={selectedOption} placeholder={"Velge "+value.name} fluid search multiple clearable selection  onChange={(event, data) => handleDropDownChange(event, data, value.id)} options={value.items} />
                            </div>
                            
                            
                          </div>
                        )) : ""}
                      </div>
                    </div>
                    <div className="row" >
                      <div className="col-12 pt-4"  >
                         <div className="col-6" id="seostats-number">
                         </div>
                         <div className="col-6" >
                         <input size="10" type="hidden" id="seostats-text" />
                         </div>
                      </div>
                    </div>
                    </>
                    )
                    : ""}
                    
                    <div className="row" >
                      <div className="mt-4">
                      <h6 className="h5">{t('selectinterval')}</h6>
                       {/*}<button className="btn btn-lg fm-btn me-md-2" type="button" onClick={() => setShowSEOIntervals(!showSEOIntervals)} >{t('selectinterval')}</button> {*/}
                        
                      </div>
                    </div>
                    {showSEOIntervals ? 
                    (
                    <>
                    <div className="row pt-4" >
                      <div className="col-1" >

                        <select className="form-control" onChange={handleSelectChange}>
                          <option value="">Select</option>
                          <option value="hourly" selected={dailyWeeklyMonthly=="hourly" ? "selected" : ""}>Hourly</option>
                          <option value="daily" selected={dailyWeeklyMonthly=="daily" ? "selected" : ""}>Daily</option>
                          <option value="weekly" selected={dailyWeeklyMonthly=="weekly" ? "selected" : ""}>Weekly</option>
                          <option value="monthly" selected={dailyWeeklyMonthly=="monthly" ? "selected" : ""}>Monthly</option>
                        </select>
                      </div>
                      <div className="col-4" >
                        {dailyWeeklyMonthly=="hourly" ?
                        (
                        <></>)
                        : dailyWeeklyMonthly=="daily" ?
                        (
                        <div className="row">
                          <div className="col-3">
                          <select className="form-control" onChange={handleTimeChange} >
                            {times.map((time, index) => (
                              <option selected={selectedTime==time+':00' ? "selected" : ""} key={index} value={time}>
                                {time}
                              </option>
                            ))}
                          </select>
                          </div>
                         </div>)
                        : dailyWeeklyMonthly=="weekly" ?
                          (
                          <div className="row">
                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.monday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({monday: !selectedDays.monday})}>M</div>
                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.tuesday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({tuesday: !selectedDays.tuesday})}>T</div>
                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.wednesday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({wednesday: !selectedDays.wednesday})}>W</div>
                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.thursday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({thursday: !selectedDays.thursday})}>T</div>
                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.friday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({friday: !selectedDays.friday})}>F</div>
                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.saturday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({saturday: !selectedDays.saturday})}>S</div>
                            <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.sunday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({sunday: !selectedDays.sunday})}>S</div>
                            <div className="col-3">
                              <select className="form-control" onChange={handleTimeChange} >
                                {times.map((time, index) => (
                                  <option selected={selectedTime==time+':00' ? "selected" : ""} key={index} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </select>
                            </div>
                            </div>)
                        : dailyWeeklyMonthly=="monthly" ?
                          (<div className="row">
                            <div  className="col-3">
                            
                            <select className="form-control" onChange={handleMonthDayChange}>
                              <option value="">Select Day</option>
                              {days.map((day) => (
                                <option key={day} value={day} selected={monthDay==day ? "selected" : ""}>
                                  {day}
                                </option>
                              ))}
                            </select>
                            </div>
                            <div className="col-3">
                              <select className="form-control" onChange={handleTimeChange} >
                                {times.map((time, index) => (
                                  <option selected={selectedTime==time+':00' ? "selected" : ""} key={index} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </select>
                              
                            </div>
                          </div>)
                        : ''
                        }
                      </div>
                      
                    </div>
                    <div className="row pt-4" >
                      <div className="col-12">
                        <button className="btn btn-md fm-btn me-md-2" type="button" onClick={() => saveSEOFrequency()} >{t('save')}</button>
                        {saveFrequencyMsg ? saveFrequencyMsg : ''}
                      </div>
                    </div>
                    </>
                    )
                    : ''}
                  </div>

                  <div className="container-fluid mt-4">
                  <hr />
                  </div>
                  <div className="container-fluid mt-4">
                    <div className="row" >
                      <div>
                        <h4 className="h4">{t('maunalseooptimize')}</h4>
                      </div>
                    </div>
                    <div className="row" >
                    
                       <div className="col-6 pt-4" >
                        <div className="col-12"><h5>{t('schedulebyproduct')}</h5></div>
                          <div className="col-12">
                              <div className="input-group mb-3">
                                <input type="text" onChange={handleChange} className="form-control" placeholder="" aria-label="" aria-describedby="" />
                                <button onClick={() => addSchedule()} className="btn fm-btn" type="button">{t('schedule')}</button>
                              </div>
                          </div>
                          {scheduleMsg ? scheduleMsg :''}
                        </div>
                    
                      </div>
                      
                  </div>
                  
                  
                  
                </main>
              </div>
            </div>
        	
        </div>
        )
}
export default WorkSchedule;