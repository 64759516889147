import {NavLink, useNavigate, useParams  } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AuthService from "../services/AuthService";
import PromptService from "../services/PromptService";
import AssistantService from "../services/AssistantService";
import "../styles/bootstrap.min.css";
import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import UserAvatar  from "../images/user-avatar.png";
import OurValues  from "../images/our-values.png";
import ToneOfVoice  from "../images/tone-voice.png";
import Language  from "../images/language.png";
import JobResult  from "../images/job-result.png";
import Feedback  from "../images/feedback.png";
import ThumbUp  from "../images/thumb-up.png";
import RestoreIcon  from "../images/restore.png";
import Notification  from "../images/notifications_none.png";
import Theme  from "../images/moon-solid.png";
import Information  from "../images/info_outline.png";
import { useTranslation } from 'react-i18next';

function JobHistory() {
  const { appslug } = useParams();
  document.body.style.backgroundColor = '#FFFFFF';
  const { t } = useTranslation();
  const [promptList, setPromptList] = useState();
  useEffect(() => {
    AssistantService.getAssistantIDBySlug(appslug).then(
      (assistresponse) => {
        PromptService.promptHistory(assistresponse.assistantID).then(
        (response) => { 

          setPromptList(response.prompts);
        }).catch(
          (error) => {
            console.log(error);
          }
        );
      }
    )
    /*PromptService.getPromptByAssisstant("1").then(
    (response) => { 
      setPromptList(response.prompts);
    }).catch(
      (error) => {
        console.log(error);
      }
    );*/
  }, [appslug])
  const restoreJobDescription = (jobid) => {
    const confirmed = window.confirm("Are you sure you want to restore this job description?")
    if (confirmed) {
      PromptService.promptRestore(jobid).then(
      (response) => { 
      console.log(response);
        //setPromptList(response.prompts);
      }).catch(
        (error) => {
          console.log(error);
        }
      );
    }
  }
  console.log(promptList);
	return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-none d-block align-items-center pt-3 pb-2 mb-3">
                      <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                          </button>
                    </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
                    <div className="col-md-3 col-lg-8 p-4 ">
                      <h1 className="h1">{t('jobhistory')}</h1>
                      <div className="breadcrumb">
                        {t('jobdescriptionforseoassistant')} &raquo; {t('jobhistory')}
                      </div>
                    </div>
                    <TopBar />
                  </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
                    <div className="col-md-3 col-lg-8 px-4 ">
                    <p>{t('jobhistorypagesubline')}</p>
                    </div>
                  </div>
                  
                  <div className="container-fluid mt-4">
                    <div className="row">
                      
                         <div className="col-12 fm-content-box p-4">
                          <div className="col-12"><h3>{t('previousjobdescription')}</h3></div>
                            <div className="col-12 table-responsive mt-4 fm-table">
                              <table className="table table-hover align-top">
                               <tbody>
                                  <tr>
                                    <th scope="col">SR.</th>
                                    <th scope="col">{t('jobdescription')}</th>
                                    <th scope="col" className="col-1">{t('action')}</th>
                                  </tr>
                                  {promptList ? promptList.map((value, key) => {
                                    let parsedPrompt;
                                    parsedPrompt = JSON.parse(value.prompt);
                                    let parsedPromptt;
                                    parsedPromptt = JSON.parse(value.prompt2);
                                    return (
                                    <>
                                    <tr>
                                      <td>{key+1}</td>
                                      <td>
                                        {parsedPrompt.map((values, index) => (
                                          <>
                                            <b>{values.question}</b>
                                            <br />
                                            <ul>
                                            {values.data.map((val, key) => (
                                              <>
                                                <li>{val.heading} - {val.description}</li>
                                              </>
                                               ))
                                              }
                                            </ul>   
                                          </>
                                        ))}
                                        {parsedPromptt.map((values, index) => (
                                          <>
                                            <b>{values.q}</b>
                                            <br />
                                            <ul>
                                            {values.a.map((val, key) => (
                                              <>
                                                <li>{val}</li>
                                              </>
                                               ))
                                              }
                                            
                                              
                                           </ul>   
                                          </>
                                        ))}
                                      </td>
                                      <td><img onClick={() => restoreJobDescription(value.id) } title={t('restore')} className="close-btn" src={RestoreIcon} /></td>
                                    </tr>
                                    </>
                                    )
                                    
                                  }) : ''}
                                  
                                   </tbody>
                                </table>

                            </div>
                            
                            
                            
                         </div>
                     </div>
                     
                     
                  </div>
                  
                  
                </main>
              </div>
            </div>
        	
        </div>
        )
}
export default JobHistory;