import axios from "axios";

class AssistantService {
    
    assistantsList(orgID) {
        
        const options = {
            method: "GET",
            headers: {"Content-Type": "application/json"},
            url: process.env.REACT_APP_API_SERVER_URL + "assistantslist?organization_id="+orgID,
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    getAssistantIDBySlug(slug){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"slug":slug},
            url: process.env.REACT_APP_API_SERVER_URL + "assistantidbyslug",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }
    
    SetSEOFrequency(orgID, assistantID, frequency, days, time, attributeIDs){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"organization_id": orgID, "assistant_id": assistantID, "frequency": frequency, "days": days, "time": time, "attribute_ids": attributeIDs},
            url: process.env.REACT_APP_API_SERVER_URL + "setseofrequency",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    SetSEOCriteriaAndSchedule(orgID, assistantID, time, attributeIDs){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"organization_id": orgID, "assistant_id": assistantID, "execution_time": time, "attribute_ids": attributeIDs},
            url: process.env.REACT_APP_API_SERVER_URL + "setseocriteriaandschedule",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }
    
    GetSEOCriteriaAndSchedule(assistantID){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"assistant_id": assistantID},
            url: process.env.REACT_APP_API_SERVER_URL + "getseocriteriaandschedule",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    GetSEOFrequency(assistantID){
            const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"assistant_id": assistantID},
            url: process.env.REACT_APP_API_SERVER_URL + "getseofrequency",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }
    
    GetAttributeGroupValuesById(attributeId) {
        const data = {
            attribute_id: attributeId
        }
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: data,
            url: process.env.REACT_APP_API_SERVER_URL + "attributegroupvaluesbyid",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

    GetAttributeValueSEOStats(attributeId, assistantID) {
        /*const data = {
            attribute_id: attributeId
        }*/
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            data: {"attribute_id": attributeId, "assistant_id": assistantID},
            url: process.env.REACT_APP_API_SERVER_URL + "attributevalueseostats",
        } 
        return axios(options)
        .then(response => {
           return response;
        })

    }

}

export default new AssistantService();