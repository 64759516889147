import { NavLink, useParams  } from 'react-router-dom';
import React, {useEffect, useState } from 'react';
import ProductService from "../services/ProductService";
import AssistantService from "../services/AssistantService";
import "../styles/bootstrap.min.css";
import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import ThumbUpBlank  from "../images/thumb-up-blank.png";
import ThumbUp from "../images/thumb-up.png";
import Delete  from "../images/delete.png";
import InfoIcon  from "../images/info.png";
import JobDesc  from "../images/job-description.png";
import CloseIcon  from "../images/close.png";
import { useTranslation } from 'react-i18next';

function Training() {
  const { appslug } = useParams();
  document.body.style.backgroundColor = '#FFFFFF';
  const { t } = useTranslation();
  const [loadingMsg, setLoadingMsg] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState("");
  const [productList, setProductList] = useState("");
  const [toggleState, setToggleState] = useState({});
  const [additionalData, setAdditionalData] = useState({});
  const [thumbUped, setThumbUped] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showDescModal, setShowDescModal] = useState(false);
  const [q1Question, setQ1Question] = useState();
  const [productDescription, setProductDescription] = useState();
   const [assistantID, setAssistantID] = useState(false);
  const [questionsData, setQuestionsData] = useState([]);
  const [q1Rows, setQ1Rows] = useState([
    { id: 1, heading: '', description: '' }
  ]);
  console.log("questionsData", questionsData);
  useEffect(() => {
     setLoadingMsg(true);
    AssistantService.getAssistantIDBySlug(appslug).then(
      (assistresponse) => {
        setAssistantID(assistresponse.assistantID);
        ProductService.exampleList(assistresponse.assistantID).then(
          (response) => {
            //console.log("EX LIST", response.data);
            const newOutputIds = response.data.map(item => item.id);
            setThumbUped(prevData => {
                // Add each output_id to the state
                const newData = { ...prevData };
                newOutputIds.forEach(outputId => {
                    newData[outputId] = outputId;
                });
                return newData;
            });
          }
        ). catch(
          (error) => {
            console.error("Error fetching item:", error);
          }
        )
        ProductService.productList(assistresponse.assistantID, 'exampled', currentPage).then(
          (response) => {
            setProductList(response.data.products);
            setTotalPages(response.data.total_pages);
            response.data.products.forEach(book => {
              fetchAdditionalData(assistresponse.assistantID, book.product_isbn);
            });
             setLoadingMsg(false);
          }
        ). catch(
          (error) => {
            console.error("Error fetching item:", error);
             setLoadingMsg(false);
          }
        )
    }
    )
  },[appslug, currentPage])
 
  const deleteOutput = (outputId) => {
    const confirmed = window.confirm("Are you sure you want to delete this item?")
    if (confirmed) {
      ProductService.deleteOutput(outputId).then(
        (response) => {
          document.getElementById("outputitem_"+outputId).style.display = 'none';
          console.log(response);
        }
      ).catch(
          (error) => {
              console.error("Error deleting item:", error);
          }
      );
    }
  }

  const exampleOutput = (updown, outputId) => {
    if(updown=="up") {
    var confirmed = window.confirm('Are you sure you want to add this to training data set?');
    } else {
    var confirmed = window.confirm('Are you sure you want to remove this from training data set?');
    }
    if(confirmed){
      ProductService.exampleOutput(assistantID, updown, outputId).then(
        (response) => {
          console.log(response);
          {updown==='up' ? 
          setThumbUped(prevData => ({ ...prevData, [outputId]: outputId })) 
          : 
          setThumbUped(prevData => {
              // Remove the specified outputId
              const { [outputId]: _, ...newData } = prevData;
              return newData;
          });
        }
        }
      ).catch(
          (error) => {
              console.error("Error deleting item:", error);
          }
      );  
    }
    
    
  }

  const fetchAdditionalData = async (assistantID, isbn) => {
    try {
      ProductService.outputList(assistantID, isbn, "exampled").then(
      (response) => {
        console.log("outputList", response.data);
        setAdditionalData(prevData => ({ ...prevData, [isbn]: response.data }));
      },
      (error) => {
        console.log(error);
      });
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const showOutputs = (isbn) => {
    
    setToggleState(prevState => ({
      ...prevState,
      [isbn]: !prevState[isbn]
    }));
  }

  const viewProductDescription = (prodDesc) => {
    setProductDescription(prodDesc);
    setShowDescModal(true);
  }
  const viewJobDescription = (outputid, outputidd) => {
    const questionsDataa = JSON.parse(outputidd);
    setQuestionsData(questionsDataa);


    const promptData = JSON.parse(outputid);
    setQ1Question(promptData[0].question);
    const questionData = promptData[0].data;
    setQ1Rows(questionData)
    //setJobDescription(outputid)

    


    handleOpenModal();
  }

  const handleCloseProdDesc = () => {
    setShowDescModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFirstClick = () => {
    setCurrentPage(1);
  };

  const handleLastClick = () => {
    setCurrentPage(totalPages);
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 7;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
    let endPage = Math.min(totalPages, currentPage + halfMaxPagesToShow);

    if (currentPage - halfMaxPagesToShow < 1) {
      endPage = Math.min(totalPages, endPage + (halfMaxPagesToShow - (currentPage - 1)));
    }

    if (currentPage + halfMaxPagesToShow > totalPages) {
      startPage = Math.max(1, startPage - ((currentPage + halfMaxPagesToShow) - totalPages));
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };
   
  const nl2br = (text) => {
    return text.split('\n').map((str, index) => (
      <>
        {str}
        <br />
      </>
    ));
  };
  
	return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-none d-block align-items-center pt-3 pb-2 mb-3">
                      <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                          </button>
                    </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3  mb-1">
                    <div className="col-md-3 col-lg-8 px-4 ">
                      <h1 className="h1">{t('training')}</h1>
                      <div className="breadcrumb">
                        {t('training')} &raquo;
                      </div>
                    </div>
                    <TopBar />
                  </div>
                  
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
                    <div className="col-md-3 col-lg-8 px-4 ">
                    <p>{t('trainingpagesubline')}</p>
                    </div>
                  </div>
                  
                  <div className="container-fluid mt-4">
                    <div className="row">
                      <div className="col-12 p-2">
                        <div className="">
                          <NavLink to={"/"+appslug+"/completed-task"}><button className="btn btn-md fm-btn me-md-2" type="button" >{t('completedtask')}</button> </NavLink> 
                              
                        </div>
                      </div>
                      
                    </div>
                    <div className="row">
                      
                         <div className="col-12 fm-content-box p-4">
                          <div className="col-12"><h3>{t('trainingoftheseoassistant')}</h3></div>
                            <div className="col-12 table-responsive mt-4 fm-table">
                              <table className="table table-hover align-top">
                               <tbody>
                                  <tr>
                                    <th scope="col"></th>
                                    <th scope="col">SR.</th>
                                    <th scope="col">{t('isbn')}</th>
                                    <th scope="col" className="col-2">{t('title')}</th>
                                    <th scope="col">{t(appslug)}</th>
                                    <th scope="col" align="right" style={{textAlign:'right'}}  width="10%">{t('action')}</th>
                                    
                                  </tr>
                                  {loadingMsg ? 
                                    (
                                    <tr><td align="center" colspan="6">{t('loadingdata')}</td></tr>
                                    )
                                     
                                    : ''}
                                  {productList ? 
                                    productList.map((val, key) =>{
                                      return (
                                        <tr>
                                          <td valign="top">{additionalData[val.product_isbn]!=null ? ( <><div className="plus-minus" onClick={() => showOutputs(val.product_isbn) }  width="12"  >{toggleState[val.product_isbn] ? "-" : "+" } </div></>
                                                  )
                                                  : '' }</td>
                                          <td>{val.serial_number}</td>
                                          <td>{val.product_isbn}</td>
                                          <td>{val.product_name}</td>
                                          <td colspan="2">
                                            <div>
                                              <div className="outputListItemOutputFirst"> 
                                                {nl2br(val.output)}
                                                
                                              </div>
                                               
                                              <div  className="outputListItemAction">
                                                <img alt={t('viewjobdescription')} className="close-btn" src={InfoIcon} onClick={() => viewProductDescription(val.product_description)} title={t('viewjobdescription')} width="18" />&nbsp;.&nbsp; 
                                                <img alt={t('viewjobdescription')} className="close-btn" src={JobDesc} onClick={() => viewJobDescription(val.prompt, val.prompt2)} title={t('viewjobdescription')} width="18" />&nbsp;.&nbsp; 
                                                {thumbUped[val.id] ? <img alt={t('removeexample')} className="close-btn" onClick={() => exampleOutput('down', val.id)}  src={ThumbUp} title={t('removeexample')} width="18" /> : <img  className="close-btn" onClick={() => exampleOutput('up',val.id)}  src={ThumbUpBlank} alt={t('setexample')} title={t('setexample')} width="18" /> }
                                            
                                              </div>
                                              <div className="outputListItemAction">&nbsp;</div>
                                            </div>
                                            <div style={{clear:'both'}}></div>
                                            <div id={val.product_isbn} style={{ display: toggleState[val.product_isbn] ? 'block' : 'none' }}>
                                                {additionalData[val.product_isbn]!=null ? (
                                                  <>
                                                  <br />
                                                  <div><b>{t('outputhistory')}</b></div>
                                                  {additionalData[val.product_isbn] ? additionalData[val.product_isbn].map((value, key) => {
                                                    return <>
                                                      <div className="outputListItem" id={"outputitem_"+value.id}>
                                                        <div className="outputListItemOutput">{nl2br(value.output)}</div> 
                                                        <div className="outputListItemAction">
                                                        <img className="close-btn" src={JobDesc} onClick={() => viewJobDescription(value.prompt, value.prompt2)} alt={t('viewjobdescription')} title={t('viewjobdescription')} width="18" />&nbsp;.&nbsp; 
                                                        <img  className="close-btn" style={{display:'none'}}  src={Delete} title={t('delete')} alt={t('delete')} onClick={() => deleteOutput(value.id)} width="18" />
                                                        {thumbUped[value.id] ? <img  className="close-btn" onClick={() => exampleOutput('down', value.id)}  src={ThumbUp} alt={t('removeexample')} title={t('removeexample')} width="18" /> : <img  className="close-btn" onClick={() => exampleOutput('up',value.id)}  src={ThumbUpBlank} alt={t('setexample')} title={t('setexample')} width="18" /> }
                                                        </div> 
                                                        <div className="outputListItemAction">&nbsp;</div> 
                                                      </div>
                                                      
                                                    </>
                                                  })
                                                  : ''}
                                                  </>
                                                  )
                                                  : '' }
                                            </div>
                                          </td>
                                          
                                        </tr>
                                        )
                                    })
                                   : ''}
                                                                                            

                                   </tbody>
                                   <tr>
                                    <td colspan="5">
                                      <button className="paging" onClick={handleFirstClick} disabled={currentPage === 1}>First</button>
                                      <button className="paging" onClick={handlePreviousClick} disabled={currentPage === 1}>Previous</button>
                                      {generatePageNumbers().map(page => (
                                        <button  className={currentPage === page ? 'currpaging' : 'paging'}
                                          key={page}
                                          onClick={() => handlePageClick(page)}
                                          
                                        >
                                          {page}
                                        </button>
                                      ))}
                                      <button className="paging" onClick={handleNextClick} disabled={currentPage === totalPages}>Next</button>
                                      <button className="paging" onClick={handleLastClick} disabled={currentPage === totalPages}>Last</button>
                                  
                                    </td>
                                   </tr>
                                </table>

                            </div>
                            
                            
                            
                         </div>
                     </div>
                     {showDescModal ? 
                     <div className="modal display-block">
                       <div className="modal-main">
                        <div style={{float: 'right', marginTop: '-15px', marginRight: '-5px'}}>
                          <img alt="close" src={CloseIcon} onClick={handleCloseProdDesc} className="close-btn" width="12" />
                        </div>
                        <div className="modal-contentt">
                        {productDescription ? productDescription : ''}
                        </div>
                       </div>
                     </div>
                     : ''}
                     {showModal ? 
                     <div className="modal display-block">
                       <div className="modal-main">
                        <div style={{float: 'right', marginTop: '-15px', marginRight: '-5px'}}>
                          <img alt="close"  src={CloseIcon} onClick={handleClose} className="close-btn" width="12" />
                        </div>
                        <div className="modal-contentt">

                          {q1Question ? 
                            (
                            <>
                            <table className="table table-hover align-middle">
                            <tbody>
                            <tr>
                              <td colspan="2">
                              <b>{q1Question}</b>
                              </td>
                            </tr>

                            {q1Rows.map((row, index) => (
                              <>
                                <tr>
                                  <td className="col-4">
                                    <input
                                      readonly="true"
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      aria-label=""
                                      aria-describedby=""
                                      value={row.heading}
                                    /> 
                                  </td>
                                  <td>
                                    <input
                                      readonly="true"
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      aria-label=""
                                      aria-describedby=""
                                      value={row.description}
                                    /> 
                                  </td>
                                </tr>
                              </>
                            ))}
                            </tbody>
                            </table>
                            </>
                            )
                            : '' }
                            {questionsData ? (
                              <><table className="table table-hover align-middle">
                            <tbody>
                            {questionsData.map((values, index) => (
                              <>
                                <tr>
                                  <td colspan="2">
                                  <b>{values.q}</b> 
                                  </td>
                                </tr>
                                
                                  {values.a.map((val, key) => (
                                  <>
                                    <tr>
                                      <td  colspan="2">
                                        <input
                                        readonly="true"
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        aria-label=""
                                        aria-describedby=""
                                        value={val}
                                      /> 
                                     </td>
                                    </tr>
                                  </>
                                   ))
                                  } 
                                  
                              </>
                            ))}
                            </tbody>
                            </table>
                            </>
                            )
                            : '' }
                        </div>
                       </div>
                     </div>
                     : ''}
                  </div>
                  
                  
                </main>
              </div>
            </div>
        	
        </div>
        )
}
export default Training;